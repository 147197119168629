import useReactRouter from "use-react-router";
import "./index.css";
import BottomNav from "../../layouts/BottomNav";
import Imglogo from "../../img/logo.png";
import { getStaffLogin } from "../../helper";
import {
  COMMISSION_SHIPER,
  HISTORY,
  HISTORY_ITEMS_BACK_BRANCH,
  ITEM_DELIVERING,
  SHIPPER_CONFIRM,
  SHIPPER_ORIGIN,
  TAB_MENU_COMPLETED,
  TAB_MENU_ITEM_IN,
} from "../../routes/app";
import {
  LIST_SHIPPER_CONFIRMED,
  LIST_SHIPPER_ITEM,
  QUERY_PAYROLL_SUMMARY,
} from "./apollo";
import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

export default function Home() {
  const { history } = useReactRouter();
  const userInfo = getStaffLogin();

  const [fetchPayroll, { data: resultPayroll }] = useLazyQuery(
    QUERY_PAYROLL_SUMMARY,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [fetchData, { data: result }] = useLazyQuery(LIST_SHIPPER_CONFIRMED, {
    fetchPolicy: "cache-and-network",
  });
  const [fetchItem, { data: DataItem }] = useLazyQuery(LIST_SHIPPER_ITEM, {
    fetchPolicy: "cache-and-network",
  });
  const [fetchDataSendBack, { data: resultSendBack }] = useLazyQuery(
    LIST_SHIPPER_ITEM,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [fetchDataOriginBranch, { data: resultSendOrigin }] = useLazyQuery(
    LIST_SHIPPER_ITEM,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchDataSendBack({
      variables: {
        where: {
          shipper: userInfo?._id,
          itemStatus: "WAITING_BACK_SHOP",
          backward: 0,
        },
      },
    });

    fetchData({
      variables: {
        where: {
          shipper: userInfo?._id,
          status: "DEPARTURE",
        },
      },
    });

    fetchItem({
      variables: {
        where: {
          shipper: userInfo?._id,
          itemStatusIn: ["ASSIGNED_SHIPPER"],
        },
      },
    });

    fetchPayroll({
      variables: {
        where: {
          empID: parseInt(userInfo?._id),
          confirmStatus: "UNCONFIRMED",
        },
        orderBy: "DESC",
        limit: 1,
      },
    });

    fetchDataOriginBranch({
      variables: {
        where: {
          shipper: userInfo?._id,
          itemStatus: "ORIGIN_BRANCH_BACKWARD",
          backwardOrigin: 1,
          isADrop: 0,
          isDeleted: 0,
        },
      },
    });
  }, [result, DataItem]);
  const totalPickup = result?.pickupOfItems?.total;
  const totalSendBack = resultSendBack?.items?.total;
  const totalItem = DataItem?.items?.total || 0;
  const totalPayroll = resultPayroll?.summaryPayroll?.total;
  const totalOrigin = resultSendOrigin?.items?.total || 0;

  return (
    <>
      <div>
        <div className="appHeader border-0">
          <div className="appHeader text-light border-0 text-right">
            <div style={{ flex: 1 }} className="text-left ml-1">
              <img src={Imglogo} alt="logo" style={{ width: 40 }} />
            </div>
            ໜ້າຫຼັກ
            <div
              className="text-white pageTitle text-right text-nowrap pr-0"
              style={{ flex: 1 }}
            >
              <a
                className="mr-3 float-right"
                onClick={() => history.push(`${SHIPPER_CONFIRM}/1`)}
              >
                {totalPickup !== 0 ? (
                  <>
                    <i className="icon-bell" style={{ fontSize: 20 }} />
                    <span className="badge badge-success mr-1 p-2">
                      <small>{totalPickup}</small>
                    </span>
                  </>
                ) : null}
              </a>
            </div>
          </div>
        </div>
        <div
          className="wallet-card mt-5 shadow"
          style={{
            backgroundColor: "transparent",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "0 0 30px 30px",
          }}
        >
          <div className="wallet-footer">
            <div className="item">
              <div
                className="icon-wrapper"
                onClick={() => history.push(`${TAB_MENU_ITEM_IN}/1`)}
              >
                <i className="fa-solid fa-circle-down fa-2x" />
              </div>
              <h5>ອໍເດີຮັບແລ້ວ</h5>
            </div>
            <div className="item">
              <a href="javascript:void(0)">
                <div
                  className="icon-wrapper"
                  onClick={() => history.push(`${ITEM_DELIVERING}/1`)}
                >
                  {totalItem !== 0 ? (
                    <span
                      className="badge badge-success ms-2"
                      style={{
                        position: "absolute",
                        marginTop: -60,
                        marginRight: -50,
                        padding: 0,
                        zIndex: 1000,
                      }}
                    >
                      <small className="p-1">{totalItem || 0}</small>
                    </span>
                  ) : null}
                  <i className="fa-solid fa-truck fa-2x" />
                </div>
                <h5>ກຳລັງຈັດສົ່ງ</h5>
              </a>
            </div>
            <div className="item">
              <div
                className="icon-wrapper"
                onClick={() => history.push(`${COMMISSION_SHIPER}/1`)}
              >
                {totalPayroll !== 0 ? (
                  <span
                    className="badge badge-success ms-2"
                    style={{
                      position: "absolute",
                      marginTop: -60,
                      marginRight: -50,
                      padding: 0,
                      zIndex: 1000,
                    }}
                  >
                    <small className="p-1">{totalPayroll || 0}</small>
                  </span>
                ) : null}
                <i className="fa-solid fa-money-bill-wave fa-2x" />
              </div>
              <h5>ສ່ວນແບ່ງ</h5>
            </div>
          </div>
          <div className="wallet-footer">
            <div className="item">
              <div
                className="icon-wrapper"
                onClick={(e) => history.push(`${TAB_MENU_COMPLETED}/1`)}
              >
                <i className="fas fa-circle-check fa-2x" />
              </div>
              <h5>ສົ່ງສຳເລັດ</h5>
            </div>
            <div className="item">
              <div
                className="icon-wrapper"
                onClick={(e) => history.push(`${SHIPPER_ORIGIN}/1`)}
              >
                {totalOrigin !== 0 ? (
                  <span
                    className="badge badge-success ms-2"
                    style={{
                      position: "absolute",
                      marginTop: -60,
                      marginRight: -50,
                      padding: 0,
                      zIndex: 1000,
                    }}
                  >
                    <small className="p-1">{totalOrigin || 0}</small>
                  </span>
                ) : null}
                <i className="fas fa-undo fa-2x" />
              </div>
              <h5>ພັດສະດຸສົ່ງຄືນເເມ່ຄ້າ</h5>
            </div>
            <div className="item">
              <div
                className="icon-wrapper"
                onClick={(e) => history.push(`${HISTORY_ITEMS_BACK_BRANCH}/1`)}
              >
                {totalSendBack !== 0 ? (
                  <span
                    className="badge badge-success ms-2"
                    style={{
                      position: "absolute",
                      marginTop: -60,
                      marginRight: -50,
                      padding: 0,
                      zIndex: 1000,
                    }}
                  >
                    <small className="p-1">{totalSendBack || 0}</small>
                  </span>
                ) : null}
                <i className="fas fa-shopping-basket fa-2x" />
              </div>
              <h5>ພັດສະດຸຄົງຄ້າງ</h5>
            </div>
          </div>
        </div>
        <br />
        <BottomNav />
      </div>
    </>
  );
}
