import { gql } from "@apollo/client";

export const LIST_ITEM_DELIVERY = gql`
  query Query($where: ItemDeliveryLogWhereInput, $skip: Int, $limit: Int) {
    itemDeliveryLogs(where: $where, skip: $skip, limit: $limit) {
      total
      data {
        _id
        shipper {
          _id
          firstName
          lastName
        }
        item {
          _id
          trackingId
          itemName
          receiverPhone
          receiverName
        }
        status
        createdDate
      }
    }
  }
`;

export const LIST_SHIPPER_ITEM = gql`
  query Items(
    $where: ItemWhereInput
    $orderBy: OrderByItem
    $limit: Int
    $skip: Int
  ) {
    items(where: $where, orderBy: $orderBy, limit: $limit, skip: $skip) {
      total
      data {
        _id
        trackingId
        itemName
        itemValueKIP
        itemValueTHB
        itemValueUSD
        receiverName
        receiverPhone
        realDeliveryPrice
        chargeOnShop
        backward
        itemStatus
        originBranch {
          title
          code
        }
        destBranch {
          title
          code
        }
        receiverProvince {
          title
        }
        receiverDistrict {
          title
        }
        receiverVillage {
          title
        }
        customer {
          id_list
          full_name
          contact_info
        }
        backwardOrigin
      }
    }
  }
`;

export const QUERY_LIST_ITEM = gql`
  query PickupOfItems(
    $where: PickupOfItemWhereInput
    $orderBy: OrderByPickupOfItem
    $limit: Int
    $skip: Int
  ) {
    pickupOfItems(
      where: $where
      orderBy: $orderBy
      limit: $limit
      skip: $skip
    ) {
      total
      data {
        _id
        amount
        customer {
          id_list
          full_name
          contact_info
        }
        shipper {
          cvID
          firstName
          lastName
          phoneNumber
        }
        branch {
          _id
          title
        }
        isCustomerCreated
        status
        provinceToPickup {
          _id
          title
        }
        districtToPickup {
          _id
          title
        }
        villageToPickup {
          _id
          title
        }
        isSignature
        description
        updatedDate
        createdDate
        receivedDate
        canceledDate
        signature {
          _id
          image
        }
      }
    }
  }
`;

export const UPDATE_LIST_ITEM = gql`
  mutation UpdatePickupOfItem($data: PickupOfItemInput!, $where: WhereById!) {
    updatePickupOfItem(data: $data, where: $where) {
      status
    }
  }
`;
export const UPDATE_ITEMS = gql`
  mutation UpdateItem($data: ItemInput!, $where: WhereById!) {
    updateItem(data: $data, where: $where) {
      status
    }
  }
`;
