import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import {
  detectPhoneNumber,
  messageError,
  messageSuccess,
  getStaffLogin,
  formatDateDash,
} from "../../../helper";
import { HOME_PAGE } from "../../../routes/app";
import BottomNav from "../../../layouts/BottomNav";
import Notiflix from "notiflix";
import "../index.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LIST_SHIPPER_CONFIRMED, UPDATE_ITEMS, UPDATE_LIST_ITEM } from "../apollo";
import InsertAmount from "./amount";
import Locations from "./location";
import { Card, Col, Row } from "react-bootstrap";
import SHIPPER_LOGO from "../../../img/delivery-bike.png";
import NODATA from "../../../img/Nodata.png";
import WHATSAPP from "../../../img/whatsapp.svg";

export default function ShipperConFirm() {
  const { history } = useReactRouter();
  const [reloadData, setReloadData] = useState(false);
  const [_item, setResult] = useState();
  const userState = getStaffLogin();
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [updatePickupOfItem] = useMutation(UPDATE_LIST_ITEM);
  const [updateItems] = useMutation(UPDATE_ITEMS);

  const [fetchData, { data: result }] = useLazyQuery(LIST_SHIPPER_CONFIRMED, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          shipper: userState?._id,
          status: "DEPARTURE",
        },
      },
    });
  }, [reloadData, result, eventSearch]);

  useEffect(() => {
    if (result) {
      setResult(result?.pickupOfItems?.data);
    }
  }, [result]);

  //ຈຳນວນທັງໝົດ
  const total = result?.pickupOfItems?.total;

  //ຢືນຢັນຮັບເຄື່ອງ ITEM
  const _updateItems = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຍົກເລິກ ແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updateResult = await updatePickupOfItem({
            variables: {
              data: {
                status: "CANCELED",
              },
              where: {
                _id: parseInt(id),
              },
            },
          });

          if (_updateResult) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setReloadData(!reloadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  const message = "ສະບາຍດີ";

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(HOME_PAGE)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ອໍເດີໃໝ່</b> 
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="container" style={{ marginTop: 70 }}>
            <div className="ms-2">ຈຳນວນ {total || 0} ລາຍການ</div>
      </div>
      <div className="mt-2">
        <div className="section">
          <div className="transactions">
                {_item && _item.length > 0 ? (
                  _item.map((item) => (
                    <Card className="shadow mb-1" style={{ width: '100%' }} >
                      <Row>
                        <Col className="col-7">
                          <div className="p-3">
                            <div className="text-center" style={{ fontWeight: 'bold' }}>ລາຍລະອຽດລູກຄ້າ</div>
                            <div>
                              <strong className="bold-nato-sans">ID: {item?.customer?.id_list}</strong>
                            </div>
                            <div style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                              <span className="text-grey">ຊື່:</span> {item?.customer?.full_name}
                            </div>
                            <a
                              className="text-link text-success"
                              target="_blank"
                              href={`https://wa.me/${detectPhoneNumber(
                                item?.customer?.contact_info
                              )}?text=${message?.replace(/<br\s*[\/]?>/gi, " ")}`}
                            >
                              <span className="text-muted"><img src={WHATSAPP} alt="Whatsapp" style={{ width: 20 }} />:</span><span className="bold-nato-sans"> {item?.customer?.contact_info}
                              </span>
                            </a>
                            <div style={{ fontWeight: 'bold' }}>
                              <strong className="bold-nato-sans">ຈຳນວນ: {item?.amount || 0} </strong>
                            </div>
                            <div className="text-nowrap bold-nato-sans">
                              ວັນທີຮັບ: {formatDateDash(item?.shipperConfirmDate)}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="d-flex justify-content-center align-items-center mt-1">
                            <img
                              src={SHIPPER_LOGO}
                              alt="logo"
                              style={{ width: 80 }}
                            />
                          </div>
                          <div className="d-flex justify-content-center align-items-center">
                            <Locations data={item} />
                          </div>
                          <div className="d-flex justify-content-center align-items-center mt-1">
                            <InsertAmount
                              data={item}
                              loadData={reloadData}
                              getData={(data) => {
                                setReloadData(data);
                              }}
                            />
                          </div>
                          <div className="d-flex justify-content-center align-items-center mt-1 mb-1">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger right rounded text-nowrap p-1"
                              onClick={() => _updateItems(item?._id)}
                            >
                              <i class="fa-solid fa-circle-exclamation me-1" />
                              ລົ້ມເຫຼວ
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  ))
                ): (<>  <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "100px" }}>
                    <img src={NODATA} style={{ width: "35%", marginTop: "27%" }} />
                    <div style={{ fontWeight: 'bold' }}>
                      ບໍ່ມີຂໍ້ມູນ
                    </div>
                  </div></>)}
            <br/>
          </div>
        </div>
      </div>
      <BottomNav />
    </>
  );
}
