import React, { useEffect, useState } from "react";
import _ from "lodash";
import useReactRouter from "use-react-router";
import { COMMISSION_SHIPER, DETAIL_HISTORY } from "../../routes/app";
import {
  formatDateDash,
  getStaffLogin,
  currency,
  messageSuccess,
  messageError,
} from "../../helper";
import { useLazyQuery, useMutation } from "@apollo/client";
import { QUERY_PAYROLL_SUMMARY, UPDATE_PAYROLL } from "../home/apollo";
import BottomNav from "../../layouts/BottomNav";
import Notiflix from "notiflix";
import { Card, Col, Row } from "react-bootstrap";
import SALARY from "../../img/salary.png";
import NODATA from "../../img/Nodata.png";

export default function CommissionHistory() {
  const { history } = useReactRouter();
  const useInfo = getStaffLogin();
  const [dataUser, setResultPayroll] = useState();
  const [reloadData, setReloadData] = useState(false);

  const [updatePayroll] = useMutation(UPDATE_PAYROLL);
  const [fetchPayroll, { data: resultPayroll }] = useLazyQuery(
    QUERY_PAYROLL_SUMMARY,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchPayroll({
      variables: {
        where: {
          empID: parseInt(useInfo?._id),
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [reloadData]);

  useEffect(() => {
    setResultPayroll(resultPayroll?.summaryPayroll?.data);
  }, [resultPayroll]);

  const _updateConfirmStatus = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const _updatePayroll = await updatePayroll({
            variables: {
              data: {
                confirmStatus: "CONFIRMED",
              },
              where: {
                _id: parseInt(id),
              },
            },
          });

          if (_updatePayroll) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
            setReloadData(!reloadData);
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      },
      () => {
        return false;
      }
    );
  };

  return (
    <>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(`${COMMISSION_SHIPER}/1`)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ປະຫວັດເງິນເດືອນ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        >
        </div>
      </div>
      <div style={{ marginTop: "70px" }}>
        <div className="section">
          <div className="transactions">
            {dataUser?.length > 0 ? (
              dataUser?.map((item) => (
                <Card
                  className="d-flex shadow mt-2 p-2"
                  onClick={() => history.push(`${DETAIL_HISTORY}/${item?._id}`)}
                >
                  <Row>
                    <Col className="col-3 d-flex justify-content-center align-items-center">
                      <img src={SALARY} alt="LOGO_SALARY" />
                    </Col>
                    <Col className="col-9">
                      <div className="text-nowrap">
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                          ຊື່ ແລະ ນາມສະກຸນ: {item?.empID?.firstName} {item?.empID?.lastName}
                        </div>
                        <div>ວັນທີ່ຢືນຢັນ: {formatDateDash(item?.confirmedDate)}</div>
                        <div>
                          ຈຳນວນເງິນ:{' '}
                          <span className="text-success" style={{ fontWeight: 'bold', fontFamily: "'Noto Sans Lao', sans-serif" }}>
                            {currency(item?.finalIncome)} ₭
                          </span>
                        </div>
                        <div>
                          ເງິນເດືອນປະຈຳເດືອນ:
                          <span className="text-danger" style={{ fontWeight: 'bold', fontFamily: "'Noto Sans Lao', sans-serif" }}>
                            {' '}
                            {item?.forMonth}/{item?.forYear}
                          </span>
                        </div>
                        <div>
                          {item?.confirmStatus === 'CONFIRMED' ? (
                            <span className="badge text-success" style={{ fontWeight: 'bold' }}>
                              ຢືນຢັນແລ້ວ
                            </span>
                          ) : (
                            <button
                              className="btn btn-success btn-block btn-md mt-1"
                              onClick={(e) => {
                                e.stopPropagation();
                                _updateConfirmStatus(item?._id);
                              }}
                            >
                              <i className="fa-regular fa-circle-check mr-1"></i>
                              ຢືັນຢັນເງິນເດືອນ
                            </button>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <div className="text-center">
                  <img src={NODATA} alt="No Data" style={{ width: '30%' }} />
                  <h3 className="text-danger mt-3"><i>ບໍ່ມີຂໍ້ມູນ</i></h3>
                </div>
              </div>
            )}
          </div>
        </div>
        <BottomNav />
      </div>
    </>
  );
}
