import React, { useEffect } from "react";
import _ from "lodash";
import { getStaffLogin, currency } from "../../helper";
import { useLazyQuery } from "@apollo/client";
import {
  ITEM_DELIVERY_LOG,
  LIST_SHIPPER_ITEM,
  QUERY_ITEM,
} from "../home/apollo";

export default function EfficiencyCommission({ startDate, endDate }) {
  const useInfo = getStaffLogin();
  const [fetchAllItem, { data: resultAll }] = useLazyQuery(QUERY_ITEM, {
    fetchPolicy: "cache-and-network",
  });
  // const [fetchAllItemOrigin, { data: resultAllOrigin }] = useLazyQuery(QUERY_ITEM, {
  //   fetchPolicy: "cache-and-network",
  // });
  const [fetchItemSent, { data: resultSent }] = useLazyQuery(QUERY_ITEM, {
    fetchPolicy: "cache-and-network",
  });
  const [fetchItemCancel, { data: resultCancel }] = useLazyQuery(
    ITEM_DELIVERY_LOG,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [fetchOriginBranch, { data: originBranch }] = useLazyQuery(
    LIST_SHIPPER_ITEM,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  useEffect(() => {
    fetchAllItem({
      variables: {
        where: {
          shipper: parseInt(useInfo?._id),
          // shipperOrigin: parseInt(useInfo?._id),
          assignedShipperDateBetween: [startDate, endDate],
        },
      },
    });
    // fetchAllItemOrigin({
    //   variables: {
    //     where: {
    //       shipperOrigin: parseInt(useInfo?._id),
    //       assignedShipperDateBetween: [startDate, endDate],
    //     },
    //   },
    // });
    fetchItemSent({
      variables: {
        where: {
          shipper: parseInt(useInfo?._id),
          itemStatusIn: ["COMPLETED", "WAITING_BACK_SHOP", "SENT_BACK_SHOP"],
          deliveryCompletedDateBetween: [startDate, endDate],
        },
      },
    });
    fetchItemCancel({
      variables: {
        where: {
          shipper: parseInt(useInfo?._id),
          dateBetween: [startDate, endDate],
        },
      },
    });
    fetchOriginBranch({
      variables: {
        where: {
          shipperOrigin: parseInt(useInfo?._id),
          itemStatus: "COMPLETED",
          deliveryCompletedDateBetween: [startDate, endDate],
          backwardOrigin: 1,
          isADrop: 0,
          isDeleted: 0,
        },
      },
    });
  }, [fetchAllItem, fetchItemCancel, fetchItemSent, startDate, endDate]);

  //ພັດສະດຸຂອງໄລເດີ
  const totalAll = resultAll?.items?.total || 0;
  //ພັດສະດຸຂອງໄລເດີຕົ້ນທາງ
  // const totalAllOrigin = resultAllOrigin?.items?.total || 0;
  //ພັດສະດຸຂອງໄລເດີໄປໄດ້ໄປສົ່ງ
  const totalSent = resultSent?.items?.total || 0;
  //ພັດສະດຸທີໄລເດີສົ່ງບໍ່າສຳເລັດ
  const totalCancel = resultCancel?.itemDeliveryLogs?.total || 0;
  //ພັດສະດຸສະດຸສົ່ງສຳເລັດຈາກປາຍທາງຫາຕົ່ນທາງ
  const totalOrigin = originBranch?.items?.total || 0;
  

  const newTotal = totalAll + totalCancel;

  let money = 0;
  let X = 0;
  let Y = 0;
  X = parseInt(totalSent) * 100;
  Y = X / newTotal;

  if (Y >= 85) {
    money = 200000;
  } else if (Y >= 81) {
    money = 150000;
  } else if (Y === 80) {
    money = 100000;
  } else if (Y <= 79) {
    money = -100000;
  }

  return (
    <>
      <span
        style={{
          fontWeight: "bold",
          color: "grey",
          fontFamily: "'Noto Sans Lao', sans-serif",
        }}
      >
        {currency(newTotal)}/ {currency(totalSent)}/ {currency(totalCancel)}/{" "}
        {parseInt(Y) || 0}%/ {currency(money)} ₭
      </span>
    </>
  );
}
