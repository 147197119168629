import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { HOME_PAGE } from "../../../routes/app";
import useReactRouter from "use-react-router";
import {
  currency,
  formatDateTime,
  getStaffLogin,
  messageError,
  messageSuccess,
  messageWarning,
} from "../../../helper";
import { useLazyQuery, useMutation } from "@apollo/client";
import { LIST_SHIPPER_ITEM } from "../apollo";
import TrackingBarcode from "../../component/Barcodedetail";
import Notiflix from "notiflix";
import { UPDATE_ITEMS } from "../../home/apollo";
import NODATA from "../../../img/Nodata.png";
import moment from "moment";
import BottomNav from "../../../layouts/BottomNav";

export default function ShipperOrigin() {
  const { history } = useReactRouter();
  const [searchValue, setSearchValue] = useState();
  const [eventSearch, setEventSearch] = useState();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const userLogin = getStaffLogin();
  const DataTime = moment().format("YYYY-MM-DD HH:mm:ss");
  const [fetchData, { data: result }] = useLazyQuery(LIST_SHIPPER_ITEM, {
    fetchPolicy: "cache-and-network",
  });
  const [updateItems, { loading }] = useMutation(UPDATE_ITEMS);

  useEffect(() => {
    fetchData({
      variables: {
        where: {
          shipperOrigin: userLogin?._id,
          itemStatus: "ORIGIN_BRANCH_BACKWARD",
          trackingId: searchValue ? searchValue : undefined,
          backwardOrigin: 1,
          isADrop: 0,
          isDeleted: 0,
        },
        orderBy: "DESC",
        limit: 0,
      },
    });
  }, [eventSearch, loading]);

  useEffect(() => {
    setItems(result?.items?.data || []);
    setTotal(result?.items?.total || 0);
  }, [result]);

  const handleClickUpdate = (id) => {
    Notiflix.Confirm.show(
      "ແຈ້ງເຕືອນ",
      "ທ່ານຕ້ອງການຢືນຢັນແທ້ ຫຼື ບໍ່?",
      "ຕົກລົງ",
      "ຍົກເລີກ",
      async () => {
        try {
          const update = await updateItems({
            variables: {
              data: {
                itemStatus: "COMPLETED",
                sentBranch: userLogin?.branch?._id,
                deliveryCompletedDate: DataTime,
              },
              where: {
                _id: parseInt(id),
              },
            },
          });
          if (update) {
            messageSuccess("ດຳເນີນການສຳເລັດ");
          }
        } catch (error) {
          console.log(error);
          messageError("ດຳເນີນການບໍ່ສຳເລັດ");
        }
      }
    );
  };

  return (
    <React.Fragment>
      <div className="appHeader text-light border-0 mr-0">
        <div style={{ flex: 1 }} className="text-left">
          <button
            className="btn text-white"
            onClick={() => history.push(HOME_PAGE)}
          >
            <i className="fa fa-chevron-left fs-4" />
          </button>
        </div>
        <b className="text-white">ພັດສະດຸສົ່ງຄືນເເມ່ຄ້າ</b>
        <div
          className="text-white pageTitle text-right text-nowrap pr-0"
          style={{ flex: 1 }}
        ></div>
      </div>
      <div className="p-1">
        <div className="d-flex mb-1" style={{ marginTop: 70 }}>
          <div className="input-group">
            <input
              type="search"
              className="form-control form-control-md"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              placeholder="ຄົ້ນຫາ Tracking ID"
            />
            <button
              type="button"
              className="btn-dark"
              style={{
                width: "70px",
                borderTopRightRadius: "0.25rem",
                borderBottomRightRadius: "0.25rem",
              }}
              onClick={(e) => setEventSearch(!eventSearch)}
            >
              <i className="icon-search1" />
            </button>
          </div>
        </div>
        <div style={{ fontWeight: "bold" }}>ຈຳນວນ: {total} ລາຍການ</div>
        {items && items.length > 0 ? (
          items.map((item, index) => (
            <Card
              className="shadow p-2 mt-1 mb-1"
              style={{ fontWeight: "bold" }}
              key={index}
            >
              <Row>
                <Col className="col-12">
                  <div className="text-center">
                    <h2 style={{ color: "grey" }}>ພັດສະດຸຕິກັບ</h2>
                  </div>
                  <div
                    className="p-2 mb-2"
                    style={{ border: "1px solid black", borderRadius: "15px" }}
                  >
                    <Row>
                      <Col className="col-lg-8 text-nowrap">
                        ຊື່ຜູ້ຝາກ: <span>{item?.customer?.full_name}</span>
                      </Col>
                      <Col className="col-lg-4 text-nowrap text-end">
                        <div>
                          ID: <span>{item?.customer?.id_list}</span>
                        </div>
                      </Col>
                    </Row>
                    <div>
                      ເບີໂທຕິດຕໍ່:{" "}
                      <span
                        style={{ fontFamily: "'Noto Sans Lao', sans-serif" }}
                      >
                        {item?.receiverPhone}
                      </span>
                    </div>
                    <div>
                      ວັນທີຕີກັບ: {formatDateTime(item?.originBackwardDate)}
                    </div>
                    <div className="text-center mt-2">ຄ່າບໍລີການທັງໝົດ</div>
                    <hr className="m-1" />
                    <div className="text-end">
                      <Row>
                        <Col className="col-6 text-nowrap text-start">
                          <div>
                            ຄ່າຂົ້ນສົ່ງ: {currency(item?.realDeliveryPrice)} ₭
                          </div>
                        </Col>
                        <Col className="col-6 text-nowrap text-end">
                          <div>{currency(item?.itemValueKIP)} ₭</div>
                          <div>{currency(item?.itemValueTHB)} ฿</div>
                          <div>{currency(item?.itemValueUSD)} $</div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="text-center">
                    <TrackingBarcode trackingNumber={item?.trackingId} />
                  </div>
                  <div className="text-center mt-2">
                    <Button
                      className="btn btn-info"
                      onClick={() => handleClickUpdate(item?._id)}
                    >
                      <i className="fa fa-check me-2" />
                      ສົ່ງສຳເລັດ
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          ))
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ marginTop: "60px" }}
          >
            <img src={NODATA} style={{ width: "130px" }} alt="No Data" />
            <div style={{ fontWeight: "bold" }}>ບໍ່ມີຂໍ້ມູນ</div>
          </div>
        )}
        <br />
      </div>
      <BottomNav />
    </React.Fragment>
  );
}
